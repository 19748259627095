import React from "react"
import Svg from "react-inlinesvg"
import { useLanguage } from "../hooks/useLanguage"
import { H2, P } from "./fonts"
import OverlayConatiner from "./overlayConatiner"
import VideoOrImage from "./videoOrImage"

export default function WhyCard({ why, imgClassName }) {
    const { switchLanguage } = useLanguage()
    return (
        <div className='image--card max-1600 ma m-p20 pl60 pr60 mb40 m-p20  m-mb0  m-pt0 m-pb0 '>
            <OverlayConatiner as={"div"} className='pt50p bg-grey block m-pt120'>
                <div className='z-2 text--overlay p60 text-white m-p20'>
                    <Svg className='mb20 max-50' src={why.icon} />
                    <H2 className='mb20'>{switchLanguage(why.headingJp, why.heading)}</H2>
                    <P className='max-350'>{switchLanguage(why.paragraphJp, why.paragraph)}</P>
                </div>
                <VideoOrImage mobileImage={why.mobileImage} mobileVideo={why.mobileVideo} videoWhen={why.video} video={why.video} image={why.image} className={"bg-image filter-brightness z-1 cover " + imgClassName} />
            </OverlayConatiner>
        </div>
    )
}
