import React from 'react'
import HalfCard from './halfCard'

export default function WhyInfo({ infos, Item, mediaResolver, className }) {
    return (
        <div className={'grid grid-cols-2 gap-x-30 max-1600 ma pl60 pr60 m-p20 m-grid-cols-1 t-grid-cols-1 m-mb0 m-gap-y-20 t-gap-y-20 m-pt0 m-pb20  ' + className}>
            {infos.map((info, index) => {
                return (
                    <HalfCard key={index} media={mediaResolver(info)}>
                        <Item info={info} />
                    </HalfCard>
                )
            })}
        </div >
    )
}
