import React, { memo, useState } from "react"
import { COLOR_STATES } from "../context/menuContext"
import { useLanguage } from "../hooks/useLanguage"
import { ReactComponent as Logo } from "../images/f-logo.svg"
import ChangeHeaderInView from "./changeHeaderinView"
import { H1 } from "./fonts"
import VideoOrImage from "./videoOrImage"

const HeroBanner = ({ title = "Hero Banner", video, image, mobileVideo, mobileImage, ...props }) => {
    return (
        <ChangeHeaderInView threshold={[0.8]} color={COLOR_STATES.LIGHT} inital={true}>
            <div className='hero--banner pos-rel h-100vh bg-video flex align-bottom overflow-hidden m-wrap m-pb20 h-mh100vh-abfo'>
                <Content title={title} video={video} image={image} mobileImage={mobileImage} mobileVideo={mobileVideo} {...props} />
            </div>
        </ChangeHeaderInView>
    )
}

export default HeroBanner

const Content = memo(({ title, video, image, imgClassName, mobileVideo, mobileImage }) => {
    return (
        <>
            <VideoOrImage mobileImage={mobileImage} mobileVideo={mobileVideo} className={"bg-image filter-brightness z-1 " + imgClassName} videoWhen={video} video={video} image={image} />
            <div className='p60 text text-white flex w-100 max-1600 mla mra z-2 justify-between align-bottom m-p30  m-mba m-mt80 m-center'>
                <H1 className='m0  max-900 m-mt30 m-mla m-mra t-mr40 balance'>{title}</H1>
                <Logo className='mb15 m-hide ' fill='white' />
            </div>
            <LanguageSwitcher />
            <LanguageSwitcherMobile />
            <div className='logo--mobile'></div>
        </>
    )
})

export const LanguageSwitcher = () => {
    const { isJapanese, isLanguageJapanese } = useLanguage()
    const [isExpanded, setIsExpanded] = useState(false)

    const toggleOpenState = () => setIsExpanded(o => !o)

    const switchLanguage = () => {
        setIsExpanded(false)
        if (isJapanese) return isLanguageJapanese(false)
        return isLanguageJapanese(true)
    }

    return (
        <div className='language--switcher m-hide w-100 text-white flex align-center justify-center'>
            <div className={`language-grid ${isJapanese?'extend':''} w-100`}>
                <div className='w-100' />
                <div className='flex flex-col align-center'>
                    <div className='flex flex-col'>
                        <button onClick={toggleOpenState} className='flex gap-10 align-center'>
                            <div className={`chevron ${isExpanded ? "active" : ""}`} /> <p>{isJapanese ? "Japanese" : "English"}</p>
                        </button>
                        <div className={`language-wrapper ${isExpanded ? "active" : ""}`}>
                            <div className='inner overflow'>
                                <button onClick={switchLanguage} className='flex align-center gap-10 mt5 text-green'>
                                    <div className='chevron op-0' /> <p>{isJapanese ? "English" : "Japanese"}</p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-100' />
            </div>
        </div>
    )
}

export const LanguageSwitcherMobile = () => {
    const { isJapanese, isLanguageJapanese } = useLanguage()
    const [isExpanded, setIsExpanded] = useState(false)

    const toggleOpenState = () => setIsExpanded(o => !o)

    const switchLanguage = () => {
        setIsExpanded(false)
        if (isJapanese) return isLanguageJapanese(false)
        return isLanguageJapanese(true)
    }
    return (
        <div className='m-show language-switcher-mobile text-white'>
            <div className='flex flex-col'>
                <button onClick={toggleOpenState} className='flex gap-10 align-center'>
                    <div className={`chevron ${isExpanded ? "active" : ""}`} /> <p>{isJapanese ? "JP" : "EN"}</p>
                </button>
                <div className={`language-wrapper ${isExpanded ? "active" : ""}`}>
                    <div className='inner overflow'>
                        <button onClick={switchLanguage} className='flex align-center gap-10 mt5 text-green'>
                            <div className='chevron op-0' /> <p>{isJapanese ? "EN" : "JP"}</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
