import React from 'react'
import Spacer from './spacer'

export default function Quote({ children }) {
    return (
        <div>
            <Spacer />
            <Spacer />
            <h2 className='max-750 ma text-center pl60 pr60'>{children}</h2>
            <Spacer />
            <Spacer />
        </div>
    )
}
