import React from 'react'
import { P, H2 } from './fonts'
import Spacer from './spacer'

export default function Understanding({ title, text }) {
    return (

        <div className='max-1600 ma text-center pl60 pr60  '>
            <Spacer />
            <H2 className='mb30 max-500 ma'>{title}</H2>
            <P className='max-500 ma text-grey'>
                {text}
            </P>
            <Spacer />
        </div>
    )
}
