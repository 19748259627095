import { graphql } from "gatsby"
import React from "react"
import Svg from "react-inlinesvg"
import ChangeHeaderInView from "../components/changeHeaderinView"
import { H3, P } from "../components/fonts"
import HeroBanner from "../components/heroBanner"
import Layout from "../components/layout"
import Quote from "../components/quote"
import Seo from "../components/seo"
import Spacer from "../components/spacer"
import Understanding from "../components/understanding"
import WhyCard from "../components/whyCard"
import WhyInfo from "../components/whyInfo"
import { COLOR_STATES } from "../context/menuContext"
import { useLanguage } from "../hooks/useLanguage"

export default function Why({ data }) {
    data = data.why
    const { switchLanguage } = useLanguage()
    return (
        <Layout>
            <Seo title='Why FSCO' />
            <HeroBanner mobileImage={data.mobileHeroImage} mobileVideo={data.mobileHeroVideoUrl} title={switchLanguage(data.heroTitleJp, data.heroTitle)} image={data.heroImage} video={data.heroVideoUrl} />
            <ChangeHeaderInView threshold={0.1} color={COLOR_STATES.DARK}>
                <Quote>{switchLanguage(data.captionJp, data.caption)}</Quote>
                <WhyInfo className='mb40' mediaResolver={mediaResolver} infos={data.offerings} Item={Item} />
                <WhyCard why={resolveToWhyHero(data)} />
                <Understanding title={switchLanguage(data.infoTitleJp, data.infoTitle)} text={switchLanguage(data.infoParagraphJp, data.infoParagraph)} />
                <WhyInfo className='mb40' mediaResolver={mediaResolver} infos={data.offerings2Info} Item={Item2} />
                <Spacer />
            </ChangeHeaderInView>
        </Layout>
    )
}

function mediaResolver(info) {
    return {
        image: info.image,
        video: info.videoLink,
        mobileVideo: info.mobileVideoLink,
        mobileImage: info.mobileImage,
    }
}

function Item({ info }) {
    const { switchLanguage } = useLanguage()
    return (
        <div>
            <Svg className='mb20 max-50' src={info.icon.url} />
            <H3 className='max-500'>{switchLanguage(info.captionJp, info.caption)}</H3>
        </div>
    )
}

function Item2({ info }) {
    const { switchLanguage } = useLanguage()
    return (
        <div>
            <Svg className='mb20 max-50' src={info.icon.url} />
            <H3 className='max-500 mb20'>{switchLanguage(info.titleJp, info.title)}</H3>
            <P className='max-400'>{switchLanguage(info.captionJp, info.caption)}</P>
        </div>
    )
}

function resolveToWhyHero(data) {
    return {
        mobileImage: data.heroOfferingMobileImage,
        mobileVideo: data.heroOfferingMobileVideo,
        heading: data.heroOfferingTitle,
        headingJp: data.heroOfferingTitleJp,
        paragraph: data.heroOfferingParagraph,
        paragraphJp: data.heroOfferingParagraphJp,
        image: data.heroOfferingImage,
        video: data.heroOfferingVideoUrl,
        icon: data.heroOfferingIcon.url,
    }
}

export const query = graphql`
    query WhyPage {
        pageData: datoCmsWhyPage {
            subtitle
            subtitleJap
        }
        why: datoCmsWhyPage {
            subtitle
            subtitleJap
            mobileHeroVideoUrl
            mobileHeroImage {
                gatsbyImageData
            }
            heroOfferingMobileImage {
                gatsbyImageData
            }
            heroOfferingMobileVideo
            heroTitle
            heroTitleJp
            heroVideoUrl
            heroImage {
                gatsbyImageData
            }
            caption
            captionJp
            heroOfferingVideoUrl
            heroOfferingTitle
            heroOfferingParagraph
            heroOfferingTitleJp
            heroOfferingParagraphJp
            heroOfferingImage {
                gatsbyImageData
            }
            heroOfferingIcon {
                url
            }
            infoTitle
            infoParagraph
            infoTitleJp
            infoParagraphJp
            offerings {
                videoLink
                mobileVideoLink
                icon {
                    url
                }
                image {
                    gatsbyImageData
                }
                mobileImage {
                    gatsbyImageData
                }
                caption
                captionJp
            }
            offerings2Info {
                caption
                captionJp
                videoLink
                mobileVideoLink
                icon {
                    url
                }
                title
                titleJp
                image {
                    gatsbyImageData
                }
                mobileImage {
                    gatsbyImageData
                }
            }
        }
    }
`
